@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .expand-border {
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }

  .expand-border::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 1px;
    background: currentColor;
    transition: all 0.3s ease;
    transform: translateX(-50%);
  }

  .expand-border:hover::after {
    width: 100%;
  }

  .container-home {
    @apply max-w-container-wh mx-auto  mt-24;
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}

.input-hidden {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition:
    max-height 0.5s ease-out,
    opacity 0.5s ease-out;
}

.input-visible {
  max-height: 100px;
  opacity: 1;
  transition:
    max-height 0.5s ease-in,
    opacity 0.5s ease-in;
}

@layer base {
  @font-face {
    font-family: "CabinetGrotesk-Variable";
    src:
      url("../public/fonts/CabinetGrotesk-Variable.woff2") format("woff2"),
      url("../public/fonts/CabinetGrotesk-Variable.woff") format("woff"),
      url("../public/fonts/CabinetGrotesk-Variable.ttf") format("truetype");
    font-weight: 100 900;
    font-display: swap;
    font-style: normal;
  }
}
@layer base {
  @font-face {
    font-family: "CabinetGrotesk";
    src:
      url("../public/fonts/CabinetGrotesk-Thin.woff2") format("woff2"),
      url("../public/fonts/CabinetGrotesk-Thin.woff") format("woff"),
      url("../public/fonts/CabinetGrotesk-Thin.ttf") format("truetype");
    font-weight: 100;
    font-display: swap;
    font-style: normal;
  }
}

@layer base {
  @font-face {
    font-family: "CabinetGrotesk";
    src:
      url("../public/fonts/CabinetGrotesk-Extralight.woff2") format("woff2"),
      url("../public/fonts/CabinetGrotesk-Extralight.woff") format("woff"),
      url("../public/fonts/CabinetGrotesk-Extralight.ttf") format("truetype");
    font-weight: 200;
    font-display: swap;
    font-style: normal;
  }
}
@layer base {
  @font-face {
    font-family: "CabinetGrotesk";
    src:
      url("../public/fonts/CabinetGrotesk-Light.woff2") format("woff2"),
      url("../public/fonts/CabinetGrotesk-Light.woff") format("woff"),
      url("../public/fonts/CabinetGrotesk-Light.ttf") format("truetype");
    font-weight: 300;
    font-display: swap;
    font-style: normal;
  }
}
@layer base {
  @font-face {
    font-family: "CabinetGrotesk";
    src:
      url("../public/fonts/CabinetGrotesk-Regular.woff2") format("woff2"),
      url("../public/fonts/CabinetGrotesk-Regular.woff") format("woff"),
      url("../public/fonts/CabinetGrotesk-Regular.ttf") format("truetype");
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }
}
@layer base {
  @font-face {
    font-family: "CabinetGrotesk";
    src:
      url("../public/fonts/CabinetGrotesk-Medium.woff2") format("woff2"),
      url("../public/fonts/CabinetGrotesk-Medium.woff") format("woff"),
      url("../public/fonts/CabinetGrotesk-Medium.ttf") format("truetype");
    font-weight: 500;
    font-display: swap;
    font-style: normal;
  }
}

@layer base {
  @font-face {
    font-family: "CabinetGrotesk";
    src:
      url("../public/fonts/CabinetGrotesk-Bold.woff2") format("woff2"),
      url("../public/fonts/CabinetGrotesk-Bold.woff") format("woff"),
      url("../public/fonts/CabinetGrotesk-Bold.ttf") format("truetype");
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }
}

@layer base {
  @font-face {
    font-family: "CabinetGrotesk";
    src:
      url("../public/fonts/CabinetGrotesk-Extrabold.woff2") format("woff2"),
      url("../public/fonts/CabinetGrotesk-Extrabold.woff") format("woff"),
      url("../public/fonts/CabinetGrotesk-Extrabold.ttf") format("truetype");
    font-weight: 800;
    font-display: swap;
    font-style: normal;
  }
}

@layer base {
  @font-face {
    font-family: "CabinetGrotesk";
    src:
      url("../public/fonts/CabinetGrotesk-Black.woff2") format("woff2"),
      url("../public/fonts/CabinetGrotesk-Black.woff") format("woff"),
      url("../public/fonts/CabinetGrotesk-Black.ttf") format("truetype");
    font-weight: 900;
    font-display: swap;
    font-style: normal;
  }
}
